import reducers from './reducer';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const configureStore = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default configureStore;
