import { SponsorshipTypes } from './types'

interface SetSponsorshipLoading {
  type: SponsorshipTypes.SPONSORSHIP_LOADING
  loading: boolean
}

interface SetSponsorshipError {
  type: SponsorshipTypes.SPONSORSHIP_ERROR
  error: string
}

export type SponsorshipAction = SetSponsorshipLoading | SetSponsorshipError;

interface InitialStateI {
  error: string | null
  loading: boolean
}

const initialState: InitialStateI = {
  error: null,
  loading: false,
}

export default function reducer(
  state: InitialStateI = initialState,
  action: SponsorshipAction
): InitialStateI {
  switch (action.type) {
    case SponsorshipTypes.SPONSORSHIP_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case SponsorshipTypes.SPONSORSHIP_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
