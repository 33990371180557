import { IValidEventResponse } from './action'
import { EventTypes } from './types'

interface SetPurchaseTicketLoading {
  type: EventTypes.TICKET_PURCHASE_LOADING
  loading: boolean
}

interface SetPurchaseTicketError {
  type: EventTypes.TICKET_PURCHASE_ERROR
  error: string
}

interface SetPurchaseTicketSuccess {
  type: EventTypes.TICKET_PURCHASE_SUCCESS
  pdfUrl: string
}

export type EventTicketAction =
  | SetPurchaseTicketLoading
  | SetPurchaseTicketError
  | SetPurchaseTicketSuccess

interface GetEventValidLoading {
  type: EventTypes.GET_EVENT_VALID_LOADING
  loading: boolean
}

interface GetEventValidError {
  type: EventTypes.GET_EVENT_VALID_ERROR
  error: string
}

interface GetEventValidSuccess {
  type: EventTypes.GET_EVENT_VALID_SUCCESS
  eventValid: IValidEventResponse | null
}

export type GetEventValidatedAction =
  | GetEventValidLoading
  | GetEventValidError
  | GetEventValidSuccess

interface InitialStateI {
  purchaseError: string | null
  purchaseLoading: boolean
  pdfUrl: string | null
  eventValidError: string | null
  eventValidLoading: boolean
  eventValid: IValidEventResponse | null
}

const initialState: InitialStateI = {
  purchaseError: null,
  purchaseLoading: false,
  pdfUrl: null,
  eventValidError: null,
  eventValidLoading: false,
  eventValid: null,
}

export default function reducer(
  state: InitialStateI = initialState,
  action: EventTicketAction | GetEventValidatedAction
): InitialStateI {
  switch (action.type) {
    case EventTypes.TICKET_PURCHASE_ERROR:
      return {
        ...state,
        purchaseError: action.error,
      }
    case EventTypes.TICKET_PURCHASE_LOADING:
      return {
        ...state,
        purchaseLoading: action.loading,
      }
    case EventTypes.TICKET_PURCHASE_SUCCESS:
      return {
        ...state,
        pdfUrl: action.pdfUrl,
      }
    case EventTypes.GET_EVENT_VALID_ERROR:
      return {
        ...state,
        eventValidError: action.error,
      }
    case EventTypes.GET_EVENT_VALID_LOADING:
      return {
        ...state,
        eventValidLoading: action.loading,
      }
    case EventTypes.GET_EVENT_VALID_SUCCESS:
      return {
        ...state,
        eventValid: action.eventValid,
      }
    default:
      return state
  }
}
