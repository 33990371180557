import 'semantic-ui-css/semantic.min.css'
import { FC, useEffect } from 'react'
import { AppProps } from 'next/app'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import qs from 'qs'
import axios from 'axios'
import { ThemeProvider } from '@emotion/react'
import { get } from 'lodash'
import * as FullStory from '@fullstory/browser';
import { globalStyles } from '../shared/globalStyles'
import store from '../redux'
import { getSite } from '../shared'
import { ScrollTopBtn, UnderConstruction } from '../components'
// redux initial actions
import { initializeApp, logoutUser, getUser } from '../redux/actions'
// set axios baseURL
import { BASE_URL, FULL_STORY, GA_MEASUREMENT_ID, UNDER_CONSTRUCTION } from '../config'
import '@fontsource/inter'
import '@fontsource/manrope'
import 'nprogress/nprogress.css'

axios.defaults.baseURL = BASE_URL
axios.defaults.timeout = 30000
axios.interceptors.request.use((request) => {
  if (
    request.data &&
    request.headers['Content-Type'] === 'application/x-www-form-urlencoded'
  ) {
    request.data = qs.stringify(request.data);
  }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const userToken = process.browser && localStorage.getItem('auth_token')
    const errorStatus = get(error, 'response.status', null)
    
    if(userToken){
      if (errorStatus === 401) {
        store.dispatch(logoutUser())
      }
    }

    return Promise.reject(error);
  }
);

//Binding events. 
Router.events.on('routeChangeStart', () => NProgress.start()); Router.events.on('routeChangeComplete', () => NProgress.done()); Router.events.on('routeChangeError', () => NProgress.done());  

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const { query: { logout }} = router
  const site = getSite(pageProps)
  
  useEffect(() => {
    // Full story
    FullStory.init({ orgId: FULL_STORY })
    // google analytics
    ReactGA.initialize(GA_MEASUREMENT_ID, {
      debug: false,
      titleCase: false,
    })
    // app redux init funcs
    const userToken = localStorage.getItem('auth_token')
    store.dispatch(initializeApp(userToken))
    if(userToken){
      if(logout){
        store.dispatch(logoutUser())
      }else{
        store.dispatch(getUser())        
      }
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ReactGA.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // if UNDER_CONSTRUCTION env true, return under construction page
  if(UNDER_CONSTRUCTION) return <UnderConstruction {...pageProps} {...site} />

  return (
    <Provider store={store}>
      <ThemeProvider theme={site.theme}>
        {globalStyles}
        <Component {...pageProps} {...site} />
        <ScrollTopBtn />
      </ThemeProvider>
    </Provider>
  )
}

export default MyApp
