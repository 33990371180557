import { pick } from 'lodash'
import { ISiteExport } from '../@types'
import * as rcobawaSite from './rcobawa'

interface IGetData {
  modules: Array<string>
}

export const getSite = ({ modules }: IGetData): ISiteExport => {
  switch (process.env.NEXT_PUBLIC_SITE) {
    case 'rcobawa':
      return pick(rcobawaSite, modules)
    default:
      return pick(rcobawaSite, modules)
  }
}
