import { css, Global } from '@emotion/react'

export const globalStyles = (
  <Global
    styles={css`
      html,
      body {
        font-family: Manrope,Inter;
        margin: 0;
        padding: 0;
        background: white;
        min-height: 100%;
      }
    `}
  />
)
