import { InquiryTypes } from './types'

interface SetInquiryLoading {
  type: InquiryTypes.INQUIRY_LOADING
  loading: boolean
}

interface SetInquiryError {
  type: InquiryTypes.INQUIRY_ERROR
  error: string
}

export type InquiryAction = SetInquiryLoading | SetInquiryError;

interface InitialStateI {
  error: string | null
  loading: boolean
}

const initialState: InitialStateI = {
  error: null,
  loading: false,
}

export default function reducer(
  state: InitialStateI = initialState,
  action: InquiryAction
): InitialStateI {
  switch (action.type) {
    case InquiryTypes.INQUIRY_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case InquiryTypes.INQUIRY_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
