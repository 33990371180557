import { AuthTypes } from './types'

interface SetLoginLoading {
  type: AuthTypes.LOGIN_LOADING
  loading: boolean
}

interface SetLogout {
  type: AuthTypes.LOGOUT_FROM_APP
}

interface SetLoginCompleted {
  type: AuthTypes.LOGIN_COMPLETED
  token: string
}

interface SetLoginError {
  type: AuthTypes.LOGIN_ERROR
  error: string
}

interface SetRenewMembership {
  type: AuthTypes.RENEW_MEMBERSHIP
  membershipType: number
}

export type AuthAction =
  | SetLoginLoading
  | SetLoginCompleted
  | SetLoginError
  | SetLogout
  | SetRenewMembership

interface SetForgotLoading {
  type: AuthTypes.FORGOT_LOADING
  loading: boolean
}

interface SetForgotError {
  type: AuthTypes.FORGOT_ERROR
  error: string
}

export type ForgotAction = SetForgotLoading | SetForgotError
interface SetResetPasswordLoading {
  type: AuthTypes.RESET_LOADING
  loading: boolean
}

interface SetResetPasswordError {
  type: AuthTypes.RESET_ERROR
  error: string
}

export type ResetPasswordAction =
  | SetResetPasswordLoading
  | SetResetPasswordError
interface SetRegisterLoading {
  type: AuthTypes.REGISTER_LOADING
  loading: boolean
}

interface SetRegisterCompleted {
  type: AuthTypes.REGISTER_COMPLETED
  userId: number
}

interface SetRegisterError {
  type: AuthTypes.REGISTER_ERROR
  error: string
}

export type RegisterAction =
  | SetRegisterLoading
  | SetRegisterCompleted
  | SetRegisterError

interface InitialStateI {
  error: string | null
  loading: boolean
  token: string | null
  forgotError: string | null
  forgotLoading: boolean
  resetError: string | null
  resetLoading: boolean
  registerError: string | null
  registerLoading: boolean
  userId: number | null
  membershipType: number | null
}

const initialState: InitialStateI = {
  error: null,
  loading: false,
  token: null,
  forgotError: null,
  forgotLoading: false,
  resetError: null,
  resetLoading: false,
  registerError: null,
  registerLoading: false,
  userId: null,
  membershipType: null,
}

export default function reducer(
  state: InitialStateI = initialState,
  action: AuthAction | ForgotAction | ResetPasswordAction | RegisterAction
): InitialStateI {
  switch (action.type) {
    case AuthTypes.LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case AuthTypes.LOGIN_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case AuthTypes.LOGIN_COMPLETED:
      return {
        ...state,
        token: action.token,
      }
    case AuthTypes.FORGOT_ERROR:
      return {
        ...state,
        forgotError: action.error,
      }
    case AuthTypes.FORGOT_LOADING:
      return {
        ...state,
        forgotLoading: action.loading,
      }
    case AuthTypes.RESET_ERROR:
      return {
        ...state,
        resetError: action.error,
      }
    case AuthTypes.RESET_LOADING:
      return {
        ...state,
        resetLoading: action.loading,
      }
    case AuthTypes.REGISTER_LOADING:
      return {
        ...state,
        registerLoading: action.loading,
      }
    case AuthTypes.REGISTER_ERROR:
      return {
        ...state,
        registerError: action.error,
      }
    case AuthTypes.REGISTER_COMPLETED:
      return {
        ...state,
        userId: action.userId,
      }
    case AuthTypes.RENEW_MEMBERSHIP:
      return {
        ...state,
        membershipType: action.membershipType,
      }
    default:
      return state
  }
}
