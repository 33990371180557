import { IMembership } from '../../../@types'
import { MemberShipTypes } from './types'

interface SetPurchaseTicketLoading {
  type: MemberShipTypes.MEMBERSHIP_PURCHASE_LOADING
  loading: boolean
}

interface SetPurchaseTicketError {
  type: MemberShipTypes.MEMBERSHIP_PURCHASE_ERROR
  error: string
}

export type MembershipPurchaseAction =
  | SetPurchaseTicketLoading
  | SetPurchaseTicketError

interface GetMembershipsLoading {
  type: MemberShipTypes.GET_MEMBERSHIPS_LOADING
  loading: boolean
}

interface GetMembershipsError {
  type: MemberShipTypes.GET_MEMBERSHIPS_ERROR
  error: string
}

interface GetMembershipsSuccess {
  type: MemberShipTypes.GET_MEMBERSHIPS_SUCCESS
  memberships: Array<IMembership>
}

export type GetMembershipsAction =
  | GetMembershipsLoading
  | GetMembershipsError
  | GetMembershipsSuccess

interface InitialStateI {
  membershipPurchaseError: string | null
  membershipPurchaseLoading: boolean
  membershipsError: string | null
  membershipsLoading: boolean
  memberships: Array<IMembership>
}

const initialState: InitialStateI = {
  membershipPurchaseError: null,
  membershipPurchaseLoading: false,
  membershipsError: null,
  membershipsLoading: false,
  memberships: [],
}

export default function reducer(
  state: InitialStateI = initialState,
  action: MembershipPurchaseAction | GetMembershipsAction
): InitialStateI {
  switch (action.type) {
    case MemberShipTypes.MEMBERSHIP_PURCHASE_ERROR:
      return {
        ...state,
        membershipPurchaseError: action.error,
      }
    case MemberShipTypes.MEMBERSHIP_PURCHASE_LOADING:
      return {
        ...state,
        membershipPurchaseLoading: action.loading,
      }
    case MemberShipTypes.GET_MEMBERSHIPS_ERROR:
      return {
        ...state,
        membershipsError: action.error,
      }
    case MemberShipTypes.GET_MEMBERSHIPS_LOADING:
      return {
        ...state,
        membershipsLoading: action.loading,
      }
    case MemberShipTypes.GET_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        memberships: action.memberships,
      }
    default:
      return state
  }
}
