import { AnyAction, CombinedState, combineReducers } from 'redux'
import { AuthTypes } from './modules/auth/types'
import auth from './modules/auth/reducer'
import support from './modules/support/reducer'
import event from './modules/event/reducer'
import membership from './modules/membership/reducer'
import sponsorship from './modules/sponsorship/reducer'

const rootReducer = combineReducers({
  auth,
  support,
  event,
  membership,
  sponsorship
})

export type RootState = ReturnType<typeof rootReducer>
const reducer = (
  state: RootState,
  action: AnyAction
): CombinedState<RootState> =>
  rootReducer(
    action.type === AuthTypes.LOGOUT_FROM_APP ? undefined : state,
    action
  )
export default reducer
